import { render, staticRenderFns } from "./PasswordRecovery.vue?vue&type=template&id=5ded8088&scoped=true&"
import script from "./PasswordRecovery.vue?vue&type=script&lang=js&"
export * from "./PasswordRecovery.vue?vue&type=script&lang=js&"
import style0 from "./PasswordRecovery.vue?vue&type=style&index=0&id=5ded8088&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ded8088",
  null
  
)

export default component.exports